@use "npm:nord/src/nord";

.contact {
  @apply flex flex-col gap-y-2 p-0 my-1;

  $icons: ("mail": "email", "key": "key", "srht": "circle-outline", "matrix": "chat-bubble", "mastodon": "mastodon");

  @for $i from 1 through 5 {
    > :nth-child(#{$i}) {
      z-index: 5 - $i;
    }
  }

  @each $name, $glyph in $icons {
    > .#{$name} > a::before {
      @apply i-mdi-#{$glyph};
    }
  }

  @media (width >= 640px) and (any-hover) {
    @apply flex-row;
  }

  @mixin list-anim() {
    clip-path: inset(-8px calc(364px / -2));

    > a {
      transition: color 0ms step-end 0ms, background-color 0ms step-start 0ms;
      background: var(--link);
      color: #{nord.$nord4};
      text-decoration-color: #{nord.$nord4};
      box-shadow: var(--shadow) 0 3px 6px 0;
    }
  }

  > li {
    @apply w-8;

    transition-duration: 0;

    @media (width >= 640px) and (any-hover) {
      clip-path: inset(0);
    }

    &::marker {
      content: "";
    }

    > a {
      @apply inline-block whitespace-nowrap line-height-5;

      color: var(--link);
      text-decoration-color: var(--link);

      &::before {
        @apply m-2;

        content: "";
      }

      &::after {
        @apply mx-1;

        content: "↗";
        display: inline-block;
        text-decoration: none;
      }
    }

    &:has(a:focus-visible) {
      > a { outline: none; }

      @include list-anim;
    }
  }

  &:not(:focus-within) > li:hover { @include list-anim; }
}

body {
  &::before {
    content: "";
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url("/assets/waves.svg");

    @media (prefers-color-scheme: light) {
      background-image: url("/assets/alpine.jpg?width=250&quality=75");
      filter: blur(10px);
      opacity: 0.25;
    }

    inset: 0;
    position: absolute;
  }
}
