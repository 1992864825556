.contact {
  flex-direction: column;
  row-gap: .5rem;
  margin-top: .25rem;
  margin-bottom: .25rem;
  padding: 0;
  display: flex;
}

.contact > :first-child {
  z-index: 4;
}

.contact > :nth-child(2) {
  z-index: 3;
}

.contact > :nth-child(3) {
  z-index: 2;
}

.contact > :nth-child(4) {
  z-index: 1;
}

.contact > :nth-child(5) {
  z-index: 0;
}

.contact > .mail > a:before {
  --un-icon: url("data:image/svg+xml;utf8,%3Csvg viewBox='0 0 24 24' vertical-align='middle' display='inline-block' width='1em' height='1em' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath fill='currentColor' d='m20 8l-8 5l-8-5V6l8 5l8-5m0-2H4c-1.11 0-2 .89-2 2v12a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2'/%3E%3C/svg%3E");
  -webkit-mask: var(--un-icon) no-repeat;
  -webkit-mask: var(--un-icon) no-repeat;
  mask: var(--un-icon) no-repeat;
  color: inherit;
  vertical-align: middle;
  background-color: currentColor;
  width: 1em;
  height: 1em;
  display: inline-block;
  -webkit-mask-size: 100% 100%;
  mask-size: 100% 100%;
}

.contact > .key > a:before {
  --un-icon: url("data:image/svg+xml;utf8,%3Csvg viewBox='0 0 24 24' vertical-align='middle' display='inline-block' width='1em' height='1em' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath fill='currentColor' d='M7 14c-1.1 0-2-.9-2-2s.9-2 2-2s2 .9 2 2s-.9 2-2 2m5.6-4c-.8-2.3-3-4-5.6-4c-3.3 0-6 2.7-6 6s2.7 6 6 6c2.6 0 4.8-1.7 5.6-4H16v4h4v-4h3v-4z'/%3E%3C/svg%3E");
  -webkit-mask: var(--un-icon) no-repeat;
  -webkit-mask: var(--un-icon) no-repeat;
  mask: var(--un-icon) no-repeat;
  color: inherit;
  vertical-align: middle;
  background-color: currentColor;
  width: 1em;
  height: 1em;
  display: inline-block;
  -webkit-mask-size: 100% 100%;
  mask-size: 100% 100%;
}

.contact > .srht > a:before {
  --un-icon: url("data:image/svg+xml;utf8,%3Csvg viewBox='0 0 24 24' vertical-align='middle' display='inline-block' width='1em' height='1em' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath fill='currentColor' d='M12 20a8 8 0 0 1-8-8a8 8 0 0 1 8-8a8 8 0 0 1 8 8a8 8 0 0 1-8 8m0-18A10 10 0 0 0 2 12a10 10 0 0 0 10 10a10 10 0 0 0 10-10A10 10 0 0 0 12 2'/%3E%3C/svg%3E");
  -webkit-mask: var(--un-icon) no-repeat;
  -webkit-mask: var(--un-icon) no-repeat;
  mask: var(--un-icon) no-repeat;
  color: inherit;
  vertical-align: middle;
  background-color: currentColor;
  width: 1em;
  height: 1em;
  display: inline-block;
  -webkit-mask-size: 100% 100%;
  mask-size: 100% 100%;
}

.contact > .matrix > a:before {
  --un-icon: url("data:image/svg+xml;utf8,%3Csvg viewBox='0 0 24 24' vertical-align='middle' display='inline-block' width='1em' height='1em' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath fill='currentColor' d='M20 2H4a2 2 0 0 0-2 2v18l4-4h14a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2'/%3E%3C/svg%3E");
  -webkit-mask: var(--un-icon) no-repeat;
  -webkit-mask: var(--un-icon) no-repeat;
  mask: var(--un-icon) no-repeat;
  color: inherit;
  vertical-align: middle;
  background-color: currentColor;
  width: 1em;
  height: 1em;
  display: inline-block;
  -webkit-mask-size: 100% 100%;
  mask-size: 100% 100%;
}

.contact > .mastodon > a:before {
  --un-icon: url("data:image/svg+xml;utf8,%3Csvg viewBox='0 0 24 24' vertical-align='middle' display='inline-block' width='1em' height='1em' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath fill='currentColor' d='M20.94 14c-.28 1.41-2.44 2.96-4.97 3.26c-1.31.15-2.6.3-3.97.24c-2.25-.11-4-.54-4-.54v.62c.32 2.22 2.22 2.35 4.03 2.42c1.82.05 3.44-.46 3.44-.46l.08 1.65s-1.28.68-3.55.81c-1.25.07-2.81-.03-4.62-.5c-3.92-1.05-4.6-5.24-4.7-9.5l-.01-3.43c0-4.34 2.83-5.61 2.83-5.61C6.95 2.3 9.41 2 11.97 2h.06c2.56 0 5.02.3 6.47.96c0 0 2.83 1.27 2.83 5.61c0 0 .04 3.21-.39 5.43M18 8.91c0-1.08-.3-1.91-.85-2.56c-.56-.63-1.3-.96-2.23-.96c-1.06 0-1.87.41-2.42 1.23l-.5.88l-.5-.88c-.56-.82-1.36-1.23-2.43-1.23c-.92 0-1.66.33-2.23.96C6.29 7 6 7.83 6 8.91v5.26h2.1V9.06c0-1.06.45-1.62 1.36-1.62c1 0 1.5.65 1.5 1.93v2.79h2.07V9.37c0-1.28.5-1.93 1.51-1.93c.9 0 1.35.56 1.35 1.62v5.11H18z'/%3E%3C/svg%3E");
  -webkit-mask: var(--un-icon) no-repeat;
  -webkit-mask: var(--un-icon) no-repeat;
  mask: var(--un-icon) no-repeat;
  color: inherit;
  vertical-align: middle;
  background-color: currentColor;
  width: 1em;
  height: 1em;
  display: inline-block;
  -webkit-mask-size: 100% 100%;
  mask-size: 100% 100%;
}

@media (width >= 640px) and (any-hover) {
  .contact {
    flex-direction: row;
  }
}

.contact > li {
  transition-duration: 0;
  width: 2rem;
}

@media (width >= 640px) and (any-hover) {
  .contact > li {
    clip-path: inset(0);
  }
}

.contact > li::marker {
  content: "";
}

.contact > li > a {
  white-space: nowrap;
  color: var(--link);
  -webkit-text-decoration-color: var(--link);
  text-decoration-color: var(--link);
  line-height: 1.25rem;
  display: inline-block;
}

.contact > li > a:before {
  content: "";
  margin: .5rem;
}

.contact > li > a:after {
  content: "↗";
  margin-left: .25rem;
  margin-right: .25rem;
  text-decoration: none;
  display: inline-block;
}

.contact > li:has(a:focus-visible) {
  clip-path: inset(-8px -182px);
}

.contact > li:has(a:focus-visible) > a {
  background: var(--link);
  color: #d8dee9;
  box-shadow: var(--shadow) 0 3px 6px 0;
  outline: none;
  text-decoration-color: #d8dee9;
  transition: color step-end, background-color step-start;
}

.contact:not(:focus-within) > li:hover {
  clip-path: inset(-8px -182px);
}

.contact:not(:focus-within) > li:hover > a {
  background: var(--link);
  color: #d8dee9;
  box-shadow: var(--shadow) 0 3px 6px 0;
  text-decoration-color: #d8dee9;
  transition: color step-end, background-color step-start;
}

body:before {
  content: "";
  background-image: url("waves.4759a234.svg");
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  inset: 0;
}

@media (prefers-color-scheme: light) {
  body:before {
    filter: blur(10px);
    opacity: .25;
    background-image: url("alpine.1a128b03.jpeg");
  }
}
/*# sourceMappingURL=index.d4f27a38.css.map */
